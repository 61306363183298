<template>
  <b-modal
    :id="modalId"
    size="lg"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @shown="setData"
  >
    <b-form
        class="email-form"
        @submit="handleSubmit"
    >
      <b-form-group
          label="Employee *"
          label-cols-md="2"
          label-align="right"
          label-for="employee"
      >
        <multiselect
          id="employee"
          v-model="form.owner"
          :options="employeesOptions"
          searchable
          track-by="value"
          label="text"
        ></multiselect>
      </b-form-group>
      <b-form-group
          label="Owner email"
          label-cols-md="2"
          label-align="right"
          label-for="owner_email"
      >
        <b-form-select
          id="owner_email"
          v-model="form.email"
          :options="emailsOptions"
        />
      </b-form-group>
      <b-form-group
        label=""
        label-cols-md="2"
        label-align="right"
      >
        <div class="col-12">
          <div class="row">
            <b-form-radio v-model="addRole" :value="false" class="col-4">Choose roles</b-form-radio>
            <b-form-radio v-model="addRole" :value="true" class="col-4">Add role</b-form-radio>
          </div>
        </div>
      </b-form-group>
      <b-form-group
          v-if="addRole"
          label="Add role *"
          label-cols-md="2"
          label-align="right"
          label-for="role"
      >
        <b-form-input
          id="role-title"
          v-model="role.title"
          type="text"
          class="mb-2"
          required
        />
        <b-form-textarea
          id="role-description"
          v-model="role.description"
          required
        >
        </b-form-textarea>
      </b-form-group>
      <b-form-group
          v-if="!addRole"
          label="Roles *"
          label-cols-md="2"
          label-align="right"
          label-for="role"
      >
        <multiselect
          v-model="form.roles"
          :options="rolesOptions"
          multiple
          track-by="id"
          label="title"
        />
      </b-form-group>
      <b-form-group
          label="Login"
          label-cols-md="2"
          label-align="right"
          label-for="login"
      >
        <b-form-input
            id="login"
            v-model="form.login"
            type="text"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
          :disabled="disabledSubmitButton"
        >
          {{ btnText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {convertObjectToFormData} from "@/utils/converters";
import { FORM_MODE } from '@/utils/constants';

export default {
  name: 'UserServiceModalForm',
  props: [
    'mode',
    'modalId',
    'employeesOptions',
    'rolesOptions',
    'employees',
    'initialData',
    'modalTitle',
    'btnText'
  ],
  data() {
    return {
      form: {
        email: '',
        owner: { value: null, text: 'Choose Employee' },
        roles: [],
        login: '',
      },
      emailsOptions: [],
      addRole: false,
      role: {
        title: '',
        description: ''
      }
    }
  },
  computed: {
    service() {
      return this.$store.state.services.service;
    },
    disabledSubmitButton() {
      return this.addRole
        ? (
          !this.role.title ||
          !this.role.description ||
          !this.form.owner.value
        ) : (
          !this.form.owner.value ||
          !this.form.roles.length
        );
    }
  },
  methods: {
    async handleSubmit(event) {
      event.preventDefault();
      if (this.addRole) {
        await this.$store.dispatch('roles/createRole', convertObjectToFormData({
          title: this.role.title,
          description: this.role.description,
          service_id: this.service.id,
        })).then((response) => {
          this.form.roles.push(response);
        });
      }
      this.$emit('onSubmit', this.form);
    },
    clearForm() {
      this.$emit('onClearForm');
      this.form.roles = [];
      this.form.email = '';
      this.form.owner = { value: null, text: 'Choose Employee' };
      this.form.login = '';
    },
    getEmailsOptions() {
      this.emailsOptions = [];
      const employee = this.employees.find(
        ({ uid }) => uid === this.form.owner.value
      );
      if (employee) {
        const serviceEmployes = this.service.service_employees.filter(
          (item) => item.employee.uid === this.form.owner.value
        );
        this.emailsOptions = employee.emails
          .filter(({ id }) => serviceEmployes.every((empl) => empl.email_id !== id))
          .map((item) => ({ value: item.id, text: item.email }));
        if (this.mode === FORM_MODE.CREATE && this.emailsOptions.length > 0 && this.form.email === '') {
          this.form.email = this.emailsOptions[0].value;
        }
        if (this.mode === FORM_MODE.EDIT && this.initialData.email) {
          this.emailsOptions.push({
            value: this.initialData.email.id,
            text: this.initialData.email.email
          });
        }
      }
    },
    setData() {
      this.emailsOptions = [];
      this.form.roles = this.initialData?.roles ?? [];
      this.form.email = this.initialData?.email?.id ?? '';
      this.form.owner = this.initialData ? { value: this.initialData.employee?.uid, text: this.initialData.employee?.name } : { value: null, text: 'Choose Employee' };
      this.form.login = this.initialData?.login ?? '';
    }
  },
  watch: {
    'form.owner.value': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getEmailsOptions();
        }
      },
    },
  }
}
</script>

<style lang="scss">
.employee-form {
  .custom-control-label {
    vertical-align: middle;
  }
}
</style>
