<template>
  <b-form
      class="email-form"
      @submit="handleSubmit"
  >
    <b-form-group
        label="Title"
        label-cols-md="2"
        label-align="left"
        label-for="title"
    >
      <b-form-input
          id="title"
          v-model="form.title"
          type="text"
      />
    </b-form-group>
    <b-form-group
        label="Url"
        label-cols-md="2"
        label-align="left"
        label-for="url"
    >
      <b-form-input
          id="url"
          v-model="form.url"
          type="text"
      />
    </b-form-group>
    <b-form-group
        label="Description"
        label-cols-md="2"
        label-align="left"
        label-for="description"
    >
      <b-form-textarea
          id="description"
          v-model="form.description"
      >
      </b-form-textarea>
    </b-form-group>
    <b-form-group
        label="Notification enabled"
        label-cols-md="2"
        label-align="left"
        label-for="notification_enabled"
    >
      <b-form-checkbox
          id="notification_enabled"
          value="1"
          unchecked-value="0"
          v-model="form.notification_enabled"
          class="mt-2"
      >
      </b-form-checkbox>
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button
          type="submit"
          variant="primary"
      >
        Update
      </b-button>
      <b-button
          type="button"
          variant="danger"
          class="ml-1"
          @click="deleteService()"
      >
        Delete
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {validateForm} from "@/utils/validation";

export default {
  name: 'EmailModalForm',
  comments: {
    validateForm
  },
  props: [
    'initialData'
  ],
  data() {
    return {
      form: {
        title: '',
        url: '',
        description: '',
        notification_enabled: 0,
      },
    }
  },
  methods: {
    deleteService() {
      this.$emit('onDeleteServiceSubmit');
    },
    handleSubmit(event) {
      event.preventDefault();
      if (validateForm({
        title: this.form.title
      })) {
        this.$emit('onSubmit', this.form);
      }
    },
    clearForm() {
      this.form.title = '';
      this.form.description = '';
      this.form.url = '';
      this.form.notification_enabled = 0;
    }
  },
  watch: {
    initialData(value) {
      this.form.title = value ? value.title : '';
      this.form.url = value ? value.url : '';
      this.form.description = value ? value.description : '';
      this.form.notification_enabled = value ? value.notification_enabled === 1 ? 1 : 0 : 0;
    }
  }
}
</script>

<style lang="scss">
  .employee-form {
    .custom-control-label {
      vertical-align: middle;
    }
  }
</style>
