<template>
  <b-modal
      :id="modalId"
      size="lg"
      :title="modalTitle"
      centered
      scrollable
      title-class="font-22"
      hide-footer
      @hidden="clearForm"
  >
    <b-form
        class="role-form"
        @submit="handleSubmit"
    >
      <b-form-group
          label="Title *"
          label-cols-md="2"
          label-align="right"
          label-for="title"
      >
        <b-form-input
            id="title"
            v-model="form.title"
            type="text"
        />
      </b-form-group>
      <b-form-group
          label="Description *"
          label-cols-md="2"
          label-align="right"
          label-for="description"
      >
        <b-form-textarea
            id="description"
            v-model="form.description"
        >
        </b-form-textarea>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
            type="submit"
            variant="primary"
        >
          {{ btnText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {validateForm} from "@/utils/validation";

export default {
  name: 'RoleModalForm',
  comments: {
    validateForm
  },
  props: [
    'modalId',
    'modalTitle',
    'initialData',
    'btnText'
  ],
  data() {
    return {
      form: {
        title: '',
        description: '',
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      if (validateForm(this.form)) {
        this.$emit('onSubmit', this.form);
      }
    },
    clearForm() {
      this.$emit('onClearForm');
      this.form.title = '';
      this.form.description = '';
    }
  },
  watch: {
    initialData(value) {
      this.form.title = value ? value.title : '';
      this.form.description = value ? value.description : '';
    }
  }
}
</script>

<style lang="scss">
.employee-form {
  .custom-control-label {
    vertical-align: middle;
  }
}
</style>
